class LazyVideo extends HTMLElement {
  connectedCallback() {
    new IntersectionObserver(([entry]) => {
      let video = this.querySelector('video')
      if (video) {
        if (entry.isIntersecting) {
          video.play()
        } else {
          video.pause()
        }
      }
    }).observe(this)
  }
}

customElements.define('lazy-video', LazyVideo)
