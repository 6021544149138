function formatPrice(price) {
  let formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    // minimumFractionDigits: 0,
    // maximumFractionDigits: 0,
  })

  return '€' + formatter.format(price).replace(/\s*€/, '')
}

class Cart {
  constructor() {
    this.data = null
    this.init()
  }

  update(data, { isFirstLoad = false } = {}) {
    this.data = data

    window.dispatchEvent(
      new CustomEvent('cart:updated', {
        bubbles: true,
        cancelable: true,
        detail: { isFirstLoad },
      })
    )
  }

  get quantity() {
    return this.data?.totalQuantity ?? 0
  }

  get subtotal() {
    return formatPrice(this.data?.cost?.totalAmount?.amount ?? '0')
  }

  get lines() {
    return (this.data?.lines?.edges ?? []).map(item => item.node)
  }

  async init() {
    try {
      let response = await fetch('/shopify/cart')
      let data = await response.json()
      this.update(data, { isFirstLoad: true })
    } catch (error) {
      console.error(error)
    }
  }
}

let cart = window.cart = new Cart()

class CartContainer extends HTMLElement {
  connectedCallback() {
    window.addEventListener('cart:updated', this.update)
  }

  update = () => {
    this.classList.toggle('cartEmpty', cart.quantity === 0)
  }
}

class CartLine extends HTMLElement {
  connectedCallback() {
    this.addEventListener('submit', this.onSubmit)
    window.addEventListener('cart:updated', this.update)
  }

  onSubmit = async (event) => {
    event.preventDefault()

    let form = event.target
    let body = new FormData(form)

    if (event.submitter) {
      body.append(event.submitter.name, event.submitter.value)
    }

    const response = await fetch(form.action, {
      method: form.method,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body,
    })

    if (!response.ok) {
      alert('An erorr occurred. Please try again later.')
      return
    }

    let data = await response.json()
    cart.update(data)
  }

  update = () => {
    let id = this.getAttribute('lineid')
    let line = cart.lines.find(item => item.id === id)
    if (line) {
      this.querySelectorAll('[data-field="quantity"]').forEach(element => {
        element.value = line.quantity
      })
      this.querySelectorAll('[data-field="subtotal"]').forEach(element => {
        element.textContent = formatPrice(line.cost.totalAmount.amount)
      })
    } else {
      this.closest('li').remove()
    }
  }
}

class CartSubtotal extends HTMLElement {
  connectedCallback() {
    window.addEventListener('cart:updated', this.update)
  }

  update = () => {
    this.textContent = cart.subtotal
  }
}

class CartQuantity extends HTMLElement {
  connectedCallback() {
    this.update()
    window.addEventListener('cart:updated', this.update)
  }

  update = () => {
    this.querySelector('span').textContent = cart.quantity
    this.setAttribute('quantity', cart.quantity)
  }
}

class CartAdder extends HTMLElement {
  connectedCallback() {
    this.addEventListener('click', this.onClick)
    this.addEventListener('submit', this.onSubmit)
  }

  onClick = (event) => {
    if (event.target.type === 'radio' || event.target.type === 'button') {
      let $selectedRadio = event.target.type === 'radio'
        ? event.target
        : this.querySelector('input[type="radio"]:checked')
      let itemPrice = Number($selectedRadio.dataset.price)

      let $amount = this.querySelector('number-stepper input')
      let amount = Number($amount.value)

      let $selectedPrice = this.querySelector('.selectedPrice')
      $selectedPrice.textContent = formatPrice(itemPrice * amount)
    }
  }

  onSubmit = async (event) => {
    event.preventDefault()

    let form = event.target
    let body = new FormData(form)

    if (event.submitter) {
      body.append(event.submitter.name, event.submitter.value)
    }

    const response = await fetch(form.action, {
      method: form.method,
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body,
    })

    if (!response.ok) {
      alert('An erorr occurred. Please try again later.')
      return
    }

    let data = await response.json()
    cart.update(data)
  }
}

class CartOpener extends HTMLElement {
  connectedCallback() {
    window.addEventListener('cart:updated', this.onUpdate)
  }

  onUpdate = (event) => {
    let button = this.querySelector('a')
    if (!event.detail.isFirstLoad) {
      button.addEventListener('animationend', () => {
        button.classList.remove('wiggle')
      })
      button.classList.add('wiggle')
    }
  }
}

class CartCloser extends HTMLElement {
  connectedCallback() {
    this.querySelector('a').addEventListener('click', this.onClick)
  }

  onClick = (event) => {
    if (window.history.state.index > 1) {
      event.preventDefault()
      window.history.back()
    }
  }
}

customElements.define('cart-container', CartContainer)
customElements.define('cart-line', CartLine)
customElements.define('cart-subtotal', CartSubtotal)
customElements.define('cart-quantity', CartQuantity)
customElements.define('cart-adder', CartAdder)
customElements.define('cart-opener', CartOpener)
customElements.define('cart-closer', CartCloser)
